import React from 'react';
import { Dropdown } from '../../../../components/Dropdown';
import { IconSpan } from '../../../../components/IconSpan';
import { addApplicationUrl } from '../../../../utils/applicant_tracking/application';

interface PropTypes {
  candidateId: number;
}

function addApplication(candidateId: number) {
  return {
    action: () => {
      window.location.href = addApplicationUrl(candidateId, true);
    },
    buttonChild: (
      <IconSpan
        spanText='Refer for another Job'
        icon={{ name: 'bi-plus-circle', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

export default function RowActionsDropdown(props: PropTypes) {
  return (
    <>
      <Dropdown
        buttonIcon={{
          name: 'bi-three-dots',
          className: 'fs-4 text-info',
        }}
        menuPlacementClass={'dropdown-menu-end'}
        menuActions={[addApplication(props.candidateId)]}
      />
    </>
  );
}
