import React, { useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { ToggleButton } from '../../../../../../../../components/ToggleButton';
import { ApplicationListingFilters } from '../../utils';
import { SearchSegmentService } from '../../../../../../../../services/v1/applicant_tracking/SearchSegmentService';
import { mapFilterToSearchSegmentRequest } from '../../utils/mapFilterToSearchSegmentRequest';
import { ApiError } from '../../../../../../../../services/ApiService/errors/ApiError';
import classNames from 'classnames';
import { SearchSegment } from '../../../../../../../../entities/v1/applicant_tracking/SearchSegment';

interface ModalProps {
  filters: ApplicationListingFilters;
  onCreateSegment: (segment: SearchSegment) => void;
  onClose: (succeeded?: boolean) => void;
}

const DUPLICATE_SEGMENT_ERROR = 'duplicate_search_segment_name_error';

function isDuplicateNameError(error: ApiError) {
  return error?.body?.code === DUPLICATE_SEGMENT_ERROR;
}

export function ModalBody(props: ModalProps) {
  const [name, setName] = useState<string>('');
  const [error, setError] = useState<ApiError>(null);
  const [isPrivate, setIsPrivate] = useState<boolean>(false);

  useEffect(() => setError(null), [name]);

  const createSegment = async () => {
    try {
      const segment = await SearchSegmentService.create({
        name,
        isPrivate,
        filter: mapFilterToSearchSegmentRequest(props.filters),
      });
      props.onCreateSegment(segment);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className='fw-semibold'>
      <div className='fs-5'>
        <div className='text-primary mb-2'>Saved Filters Name</div>
        <Input
          className={classNames(
            'form-control fs-5',
            isDuplicateNameError(error) && 'is-invalid',
          )}
          placeholder='Insert Saved Filters Name'
          value={name ?? ''}
          onChange={(v) => setName(v.target.value)}
        />
        {isDuplicateNameError(error) && (
          <div className='text-danger-500 fs-6 mt-2'>
            Filter name already exists
          </div>
        )}
      </div>
      <div className='d-flex mt-4 justify-content-between align-items-center'>
        <div className='d-flex align-items-center'>
          <ToggleButton
            size='sm'
            name='private'
            checked={isPrivate}
            onChange={() => setIsPrivate(!isPrivate)}
          />
          <div className='ms-2 fs-5 fw-normal text-dark-500'>Private</div>
        </div>
        <div className='d-flex justify-content-end'>
          <Button
            color='danger'
            className='me-2'
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
          <Button
            disabled={name.length === 0 || error !== null}
            color='primary'
            onClick={createSegment}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
