import classNames from 'classnames';
import React, { ChangeEventHandler } from 'react';
import { Col } from 'reactstrap';
import { SelectOption } from '../Select';
import { TruncateText } from '../TruncateText';

interface PropTypes {
  option: SelectOption;
  checked: boolean;
  onClick: ChangeEventHandler<HTMLInputElement>;
}

export function SelectItem(props: PropTypes) {
  return (
    <>
      {props.option.isTitle ? (
        <div className='fs-5 text-dark-500 fw-bold disabled'>
          {props.option.label}
        </div>
      ) : (
        <div
          className={classNames('item-renderer', 'align-items-center', {
            disabled: props.option.isDisabled,
          })}
        >
          <input
            className='form-check-input'
            type='checkbox'
            onChange={props.onClick}
            checked={props.checked}
            tabIndex={-1}
            disabled={props.option.isDisabled}
          />
          <div
            className={classNames('d-flex flex-nowrap', {
              'justify-content-between align-items-center':
                props.option.subLabel,
            })}
          >
            <Col
              className={classNames({
                'text-truncate': props.option.subLabel,
              })}
            >
              <TruncateText
                text={props.option.label}
                ellipsize={true}
                tooltip={true}
              />
            </Col>
            {props.option.subLabel && (
              <Col
                className={classNames(
                  'text-truncate',
                  'text-end',
                  'fs-6',
                  'text-dark-200',
                )}
              >
                {props.option.subLabel}
              </Col>
            )}
          </div>
        </div>
      )}
    </>
  );
}
