import snakecaseKeys from 'snakecase-keys';
import { TagList } from '../../../../entities/v1/applicant_tracking/TagList';
import { ApiService } from '../../../ApiService';

export interface ListParams {
  applicationId?: number;
}

export interface ListParams {
  applicationId?: number;
  name?: string;
  page?: number;
  pageSize?: number;
  expand?: string[];
}

export class TagService {
  public static list(params: ListParams): Promise<TagList> {
    return ApiService.get<TagList>(
      '/api/v1/applicant_tracking/tags',
      snakecaseKeys(params),
    );
  }
}
