import React from 'react';
import {
  LabelledMultiSelect,
  LabelledMultiSelectPropTypes,
} from '../LabelledMultiSelect';
import { SelectOption } from '../Select';

type SortDirection = 'asc' | 'desc';

interface PropTypes extends LabelledMultiSelectPropTypes {
  options: GroupedOption[];
  sortCategoriesDirection?: SortDirection;
}

export interface GroupedOption extends SelectOption {
  category: string;
}

function alphabeticLabelSort(a: SelectOption, b: SelectOption) {
  return a.label.localeCompare(b.label);
}

function groupOptionsByTitle(options: GroupedOption[]) {
  const categoryMap = {};

  options.forEach((option) => {
    if (!categoryMap[option.category]) {
      categoryMap[option.category] = [];
    }

    categoryMap[option.category].push(option);
  });

  return categoryMap;
}

function spreadOptionsWithCategory(
  categoryMap: {
    [key: string]: GroupedOption[];
  },
  sortCategoriesDirection: SortDirection,
): SelectOption[] {
  let optionsWithTitle = [];
  const sortedCategories = Object.keys(categoryMap).sort();
  sortCategoriesDirection === 'desc' && sortedCategories.reverse();

  sortedCategories.forEach((category) => {
    optionsWithTitle.push({
      label: category,
      value: category,
      isTitle: true,
    });
    optionsWithTitle = optionsWithTitle.concat(
      categoryMap[category].sort(alphabeticLabelSort),
    );
  });

  return optionsWithTitle;
}

// Filters out category titles to not appear in search results
function filterOptions(
  options: GroupedOption[],
  filter: string,
): GroupedOption[] {
  if (!filter) return options;

  return options.filter(
    (option) =>
      !option.isTitle &&
      option.value &&
      option.label.toLowerCase().includes(filter.toLowerCase()),
  );
}

export function groupOptionsByCategory(
  options: GroupedOption[],
  sortCategoriesDirection: SortDirection,
): SelectOption[] {
  const categoryMap = groupOptionsByTitle(options);

  return spreadOptionsWithCategory(categoryMap, sortCategoriesDirection);
}

export function GroupedMultiSelect(props: PropTypes) {
  const optionsWithCategoryTitle = groupOptionsByCategory(
    props.options,
    props.sortCategoriesDirection || 'asc',
  );

  return (
    <LabelledMultiSelect
      {...props}
      options={optionsWithCategoryTitle}
      filterOptions={filterOptions}
    />
  );
}
