import React from 'react';
import { FormState } from 'react-hook-form';
import { Button } from 'reactstrap';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { Candidate } from '../../../../../entities/applicant_tracking/Candidate';
import { CandidateDto } from '../../../../../services/applicant_tracking/CandidateService/CandidateDto';
import CandidateForm from '../../CandidateForm';
import { mergeCandidateApplications } from '../../utils/mergeCandidateApplications';
import { redirectWithAlert } from '../../../../../services/FlashService';

interface PropTypes {
  candidate: Candidate;
  dto?: CandidateDto;
  referralFlow?: boolean;
  onSuccess: (c: Candidate, dto: CandidateDto) => void;
  onStep: (n: number) => void;
}

export function CandidateProfileStep(props: PropTypes) {
  function handleSuccessfulSubmit(
    candidate: Candidate,
    e: React.SyntheticEvent,
    dto: CandidateDto,
  ) {
    const nativeEvent = e.nativeEvent as any;
    const button = nativeEvent.submitter.value;

    props.onSuccess(candidate, dto);
    if (button === 'save_as_draft') {
      redirectWithAlert(
        '/candidates?tab=all',
        'The candidate draft was successfully created!',
      );
    } else if (button === 'save_and_continue') {
      props.onStep(1);
    }
  }

  return (
    <CandidateForm
      onSuccessfulSubmit={handleSuccessfulSubmit}
      headerFactory={(formState) => (
        <FormHeader
          formState={formState}
          redirectUrl='/candidates'
          referralFlow={props.referralFlow}
        />
      )}
      candidateDto={
        props.dto || mergeCandidateApplications(props.candidate, [])
      }
      referralFlow={props.referralFlow}
    />
  );
}

function FormHeader(props: {
  formState: FormState<CandidateDto>;
  redirectUrl: string;
  referralFlow: boolean;
}) {
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex'>
        <h2>Candidate Profile</h2>
        <div className='ms-auto d-flex gap-3'>
          {!props.referralFlow && (
            <>
              <Button
                type='submit'
                value='save_as_draft'
                disabled={!props.formState.isValid}
                className='text-info border-info'
              >
                Save as Draft
              </Button>
              <VerticalDivider />
            </>
          )}
          <Button
            color='danger'
            onClick={() => (window.location.href = props.redirectUrl)}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            value='save_and_continue'
            color='primary'
            disabled={!props.formState.isValid}
          >
            {props.referralFlow ? 'Next' : 'Save and Continue'}
          </Button>
        </div>
      </div>
    </div>
  );
}
