import React, { RefObject, useEffect, useState } from 'react';
import { LabelledSelect } from '../../../../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../../../../components/Select';
import { TriggerRowDropdown } from '../Dropdown/TriggerRowDropdown';
import {
  AutoRejectTrigger,
  SendEmailTrigger,
  Trigger,
} from '../../../../../../../../entities/applicant_tracking/Trigger';
import { ApplicationFormTemplate } from '../../../../../../../../entities/applicant_tracking/ApplicationFormTemplate';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { GroupedSelect } from '../../../../../../../../components/GroupedReactSelect';
import {
  answerSelectOptions,
  getSelectedQuestions,
  mapGroupedSelectOptions,
  selectedAnswerFromOptions,
  selectedCustomQuestionId,
  selectedDefaultQuestionField,
} from './utils';
import { CheckboxButton } from '../../../../../../../../components/CheckboxButton';
import { EmailTemplate } from '../../../../../../../../entities/EmailTemplate';
import { EmailTemplateDropdown } from '../Dropdown/EmailTemplateDropdown';
import { DelayDropdown, delayOptionValues } from '../Dropdown/DelayDropdown';
import { mapEmailTemplatesToSelectOptions } from '../../triggerSelectOptions';
import { createPortal } from 'react-dom';

interface PropTypes {
  applicationFormTemplate: ApplicationFormTemplate;
  counter: number;
  trigger: Trigger;
  formData: Trigger[];
  jobLocation: string;
  emailTemplates: EmailTemplate[];
  addtionalRowPortal: RefObject<HTMLDivElement>;
  onUpdateTrigger: (trigger: Trigger, index: number) => void;
}

function filterOutDefaultEmailTemplates(emailTemplates: EmailTemplate[]) {
  // To filter out all default templates except the rejection template
  const EXCEPTIONS = ['Rejection Template'];

  return emailTemplates.filter(
    (template) =>
      !template.default ||
      (template.default && EXCEPTIONS.includes(template.name)),
  );
}

export function AutoRejectTriggerRow(props: PropTypes) {
  const trigger = props.trigger as AutoRejectTrigger;
  const initApplicationFormQuestions = mapGroupedSelectOptions(
    props.applicationFormTemplate,
    props.jobLocation,
    trigger.applicationField || trigger.questionId?.toString(),
    getSelectedQuestions(props.formData as AutoRejectTrigger[], trigger),
  );
  const filteredEmailTemplates = filterOutDefaultEmailTemplates(
    props.emailTemplates,
  );

  const [questionOptions, setQuestionOptions] = useState(
    initApplicationFormQuestions,
  );
  const [answerOptions, setAnswerOptions] = useState(
    answerSelectOptions(trigger.rejectAnswer),
  );
  const [sendRejectionEmail, setSendRejectionEmail] = useState(
    trigger.sendRejectionEmail || false,
  );
  const [emailTemplateOptions, setEmailTemplateOptions] = useState(
    mapEmailTemplatesToSelectOptions(
      filteredEmailTemplates,
      trigger.emailTemplateId?.toString(),
    ),
  );
  const [delayOptions, setDelayOptions] = useState(
    delayOptionValues(trigger, true),
  );

  const updateTrigger = () => {
    props.onUpdateTrigger(
      {
        ...props.trigger,
        type: 'auto_reject',
        questionId: selectedCustomQuestionId(questionOptions),
        applicationField: selectedDefaultQuestionField(questionOptions),
        rejectAnswer: selectedAnswerFromOptions(answerOptions),
        delayDays: parseInt(delayOptions.find((v) => v.selected)?.value ?? ''),
        emailTemplateId: parseInt(
          emailTemplateOptions.find((v) => v.selected)?.value,
        ),
        sendRejectionEmail,
      },
      props.counter,
    );
  };

  useEffect(updateTrigger, [
    questionOptions,
    answerOptions,
    sendRejectionEmail,
    emailTemplateOptions,
    delayOptions,
  ]);

  if (!props.addtionalRowPortal.current) return null;

  return (
    <>
      <TriggerRowDropdown
        className={classNames(styles['triple-width-dropdown'])}
      >
        <GroupedSelect
          mandatory
          isSearchable
          options={questionOptions}
          defaultValue={questionOptions
            .flatMap((v) => v.options)
            .find((v) => v.selected)}
          labelClassName='fs-5'
          label='Application form field'
          placeholder='Select question'
          onChange={(option) =>
            setQuestionOptions(
              mapGroupedSelectOptions(
                props.applicationFormTemplate,
                props.jobLocation,
                option?.value,
                getSelectedQuestions(
                  props.formData as AutoRejectTrigger[],
                  trigger,
                ),
              ),
            )
          }
        />
      </TriggerRowDropdown>
      <TriggerRowDropdown>
        <LabelledSelect
          mandatory
          disabled={
            !(
              selectedCustomQuestionId(questionOptions) ||
              selectedDefaultQuestionField(questionOptions)
            )
          }
          options={answerOptions}
          selected={answerOptions.find((v: SelectOption) => v.selected)}
          labelClassName='fs-5'
          label='Application form answer'
          placeholder='Select Answer'
          onChange={(option) =>
            setAnswerOptions(answerSelectOptions(option.value === 'yes'))
          }
        />
      </TriggerRowDropdown>
      {createPortal(
        <div className='d-flex flex-nowrap justify-content-start row gx-3 mt-2h align-items-center'>
          <CheckboxButton
            name={`sendRejectionEmail-${trigger.uniqueKey}`}
            checked={sendRejectionEmail}
            label='Send Rejection Email'
            labelClasses={classNames(styles['checkbox'])}
            onChange={() => setSendRejectionEmail(!sendRejectionEmail)}
          />
          <EmailTemplateDropdown
            hideLabel
            disabled={!sendRejectionEmail}
            trigger={props.trigger as SendEmailTrigger}
            emailTemplates={filteredEmailTemplates}
            emailTemplateOptions={emailTemplateOptions}
            setEmailTemplateOptions={setEmailTemplateOptions}
            isMandatory={true}
          />
          <DelayDropdown
            hideLabel
            useWideDropdown
            suffixLabel
            disabled={!sendRejectionEmail}
            trigger={props.trigger}
            delayOptions={delayOptions}
            setDelayOptions={setDelayOptions}
          />
        </div>,
        props.addtionalRowPortal.current,
      )}
    </>
  );
}
