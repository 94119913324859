import React from 'react';
import { IconButton } from '../../../../../../../components/IconButton';
import { SpanTooltip } from '../../../../../../../components/SpanTooltip';

interface PropTypes {
  iconName: string;
  buttonText?: string;
  toolTipText?: string;
  disabled?: boolean;
  onClick: () => void;
  analyticsId?: string;
}

export function BulkActionButton(props: PropTypes) {
  return props.toolTipText ? (
    <SpanTooltip
      text={
        <IconButton
          color='secondary'
          iconName={props.iconName}
          buttonText={props.buttonText}
          iconClass='lh-sm fs-4 text-info'
          buttonClass='w-auto me-2 border-info'
          iconTextClass='fs-5 fw-normal'
          disabled={props.disabled}
          onClick={props.onClick}
          analyticsId={props.analyticsId}
        />
      }
      tooltipText={props.toolTipText}
      placement='top'
      className='pt-2'
      tooltipStyle={{ maxWidth: '250px' }}
    />
  ) : (
    <IconButton
      color='secondary'
      iconName={props.iconName}
      iconClass='lh-sm fs-4 text-info'
      buttonClass='w-auto me-2 border-info'
      buttonText={props.buttonText}
      iconTextClass='fs-5 fw-normal'
      disabled={props.disabled}
      onClick={props.onClick}
      analyticsId={props.analyticsId}
    />
  );
}
