import React, { useEffect } from 'react';
import { SelectOption } from '../../../../../../../../components/Select';
import { TriggerRowDropdown } from './TriggerRowDropdown';
import { Trigger } from '../../../../../../../../entities/applicant_tracking/Trigger';
import { mapRejectReasonsToSelectOptions } from '../../triggerSelectOptions';
import { RejectReason } from '../../../../../../../../entities/applicant_tracking/RejectReason';
import { GroupedMultiSelect } from '../../../../../../../../components/GroupedMultiSelect';

interface PropTypes {
  label: string;
  trigger: Trigger;
  rejectReasons: RejectReason[];
  selectedOptions: number[];
  setSelectedRejectReasonIds: (stages: number[]) => void;
}

function selectedOptions(
  rejectReasons: RejectReason[],
  selectedOptions: number[],
) {
  return rejectReasons
    .filter((i) => selectedOptions?.some((v) => v === i.id))
    .map((v) => {
      return {
        value: v.id.toString(),
        label: v.name,
        select: true,
      };
    });
}

export function RejectReasonsDropdown(props: PropTypes) {
  useEffect(() => {
    if (props.rejectReasons != null) {
      props.setSelectedRejectReasonIds(
        props.rejectReasons
          .filter((i) => props.selectedOptions?.some((v) => v === i.id))
          .map((v) => v.id),
      );
    }
  }, [props.rejectReasons]);

  return (
    <TriggerRowDropdown>
      <GroupedMultiSelect
        options={mapRejectReasonsToSelectOptions(props.rejectReasons)}
        selected={selectedOptions(props.rejectReasons, props.selectedOptions)}
        label={props.label}
        placeholder='All Reject Reasons'
        onChange={(options: SelectOption[]) =>
          props.setSelectedRejectReasonIds(
            options.filter((o) => !o.isTitle).map((v) => parseInt(v.value)),
          )
        }
        singleSelectedText='1 selected'
        manySelectedText='%d selected'
        labelClassName='fs-5'
      />
    </TriggerRowDropdown>
  );
}
