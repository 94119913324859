import React from 'react';
import { SelectOption } from '../../../../../../../../components/Select';
import { TriggerRowDropdown } from './TriggerRowDropdown';
import { SendEmailTrigger } from '../../../../../../../../entities/applicant_tracking/Trigger';
import { MultiSelectAsyncFilter } from '../../../../../../../../components/MultiSelectAsyncFilter';
import { humanize } from '../../../../../../../../utils/humanize';
import { mapApplicationStatusesToSelectOptions } from '../../triggerSelectOptions';

interface PropTypes {
  trigger: SendEmailTrigger;
  selectedStatusOptions: string[];
  setSelectedStatusOptions: (options: string[]) => void;
}

export function StatusDropdown(props: PropTypes) {
  return (
    <TriggerRowDropdown>
      <MultiSelectAsyncFilter
        isMulti={true}
        loadOptions={(name, _page) =>
          mapApplicationStatusesToSelectOptions(
            name,
            props.selectedStatusOptions,
          )
        }
        selected={props.selectedStatusOptions?.map((v) => {
          return {
            value: v,
            label: humanize(v),
            selected: true,
          };
        })}
        label='Candidate status'
        placeholder='Select candidate status'
        onChange={(options: SelectOption[]) =>
          props.setSelectedStatusOptions(options.map((v) => v.value))
        }
        mandatory
      />
    </TriggerRowDropdown>
  );
}
