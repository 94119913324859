import { Col } from 'reactstrap';
import { MultiSelectAsyncFilter } from '../../../../../../../../components/MultiSelectAsyncFilter';
import React from 'react';
import { ApplicationListingFilters } from '../../utils';
import { mapResultToSelectOption } from '../../../../../../../../utils/mapResultToSelectOption';
import { TagService } from '../../../../../../../../services/v1/applicant_tracking/TagService';

interface PropTypes {
  isLoading: boolean;
  filters: ApplicationListingFilters;
  setFilters: (filters: ApplicationListingFilters) => void;
}

export function TagsFilter(props: PropTypes) {
  const loadSources = (name: string, page: number) =>
    mapResultToSelectOption(
      TagService.list({ name, page }),
      (filter) => filter.tags,
      false,
    );

  return (
    <Col xs='3' className='mt-3h'>
      <MultiSelectAsyncFilter
        isMulti
        label='Tag'
        placeholder='Select tags'
        loadOptions={loadSources}
        onChange={(tags) => props.setFilters({ ...props.filters, tags })}
        className='ps-0 fs-5 fw-semibold'
        disabled={props.isLoading}
        selected={props.filters?.tags || []}
      />
    </Col>
  );
}
