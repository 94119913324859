import { SelectOption } from '../../../../../../../components/Select';
import {
  SearchSegment,
  SearchSegmentFilter,
} from '../../../../../../../entities/v1/applicant_tracking/SearchSegment';
import { JobService } from '../../../../../../../services/v1/applicant_tracking/JobService';
import { JobStageService } from '../../../../../../../services/v1/applicant_tracking/JobStageService';
import { LocationService } from '../../../../../../../services/v1/applicant_tracking/LocationService';
import {
  ListParams,
  SourceService,
} from '../../../../../../../services/v1/applicant_tracking/SourceService';
import { stringToDateRange } from '../../../../../../../utils/stringToDateRange';
import { dateStrToMoment } from '../../../../../../../utils/dateStrToMoment';
import { SourceList } from '../../../../../../../entities/v1/applicant_tracking/SourceList';
import { JobList } from '../../../../../../../entities/v1/JobList';
import { JobStageList } from '../../../../../../../entities/v1/JobStageList';
import { LocationList } from '../../../../../../../entities/v1/LocationList';
import { SOURCING_CHANNELS } from '../../../../../../../entities/applicant_tracking/Source';
import { ApplicationListingFilters, DEFAULT_FILTER_STATE } from '.';
import {
  DateFilter,
  DateRange,
  getDateFilterFromDateRange,
} from '../../../../../../../components/DateRangePicker';
import { TagList } from '../../../../../../../entities/v1/applicant_tracking/TagList';
import { TagService } from '../../../../../../../services/v1/applicant_tracking/TagService';
import { humanize } from '../../../../../../../utils/humanize';

interface ResourceEntity {
  id: number | string;
  name: string;
}

async function fetchAllSelectedValues<T>(
  ids: number[],
  fetchFunction: (params: ListParams) => Promise<T>,
  extractData: (response: T) => ResourceEntity[],
) {
  if (ids == null || ids.length === 0) return [];

  const pageSize = 100;
  const page = 1;

  const response = await fetchFunction({ ids, pageSize, page });

  return extractData(response);
}

function mapIdsToOptions(
  ids: number[] | string[],
  entities: ResourceEntity[],
): SelectOption[] {
  if (!ids) return [];

  return ids
    .map((entityId) => entities.find((E) => E.id === entityId))
    .filter((entity): entity is ResourceEntity => !!entity)
    .map((entity) => ({
      value: entity.id.toString(),
      label: entity.name,
    }));
}

function mapStatusesOptions(statuses: string[]): SelectOption[] {
  return statuses?.map((v) => {
    return {
      value: v,
      label: humanize(v),
      selected: true,
    };
  });
}

function getDateFilter(filter: SearchSegmentFilter): DateFilter {
  if (filter.dateRange) {
    const dateRange = stringToDateRange(filter.dateRange);

    if (dateRange !== DateRange.CustomRange) {
      return getDateFilterFromDateRange(dateRange);
    }
  }

  return {
    start: dateStrToMoment(filter.startDate?.toString()),
    end: dateStrToMoment(filter.endDate?.toString()),
    range: filter.dateRange ? DateRange.CustomRange : null,
  };
}

export async function mapSearchSegmentToFilters(
  segment: SearchSegment,
): Promise<ApplicationListingFilters> {
  const [jobs, jobStages, sources, locations, tags] = await Promise.all([
    fetchAllSelectedValues<JobList>(
      segment.filter.jobIds,
      JobService.list,
      (res) => res.jobs,
    ),
    fetchAllSelectedValues<JobStageList>(
      segment.filter.jobStageIds,
      JobStageService.list,
      (res) => res.jobStages,
    ),
    fetchAllSelectedValues<SourceList>(
      segment.filter.sourceIds,
      SourceService.list,
      (res) => res.sources,
    ),
    fetchAllSelectedValues<LocationList>(
      segment.filter.rightToWorkLocationIds,
      LocationService.list,
      (res) => res.locations,
    ),
    fetchAllSelectedValues<TagList>(
      segment.filter.tagIds,
      TagService.list,
      (res) => res.tags,
    ),
  ]);

  return {
    channels: mapIdsToOptions(segment.filter.channels, SOURCING_CHANNELS),
    jobs: mapIdsToOptions(segment.filter.jobIds, jobs),
    sources: mapIdsToOptions(segment.filter.sourceIds, sources),
    jobStages: mapIdsToOptions(segment.filter.jobStageIds, jobStages),
    rightToWorkLocations: mapIdsToOptions(
      segment.filter.rightToWorkLocationIds,
      locations,
    ),
    statuses: mapStatusesOptions(segment.filter.applicationStatuses),
    rightToWorkLocationValue: segment.filter.rightToWorkLocationOperator,
    dateRange: getDateFilter(segment.filter),
    sortingColumn: {
      columnName: DEFAULT_FILTER_STATE.sortingColumn.columnName,
      direction: DEFAULT_FILTER_STATE.sortingColumn.direction,
    },
    name: segment.filter.name,
    nameInclusionValue: segment.filter.nameOperator ?? 'includes',
    email: segment.filter.email,
    emailInclusionValue: segment.filter.emailOperator ?? 'includes',
    resume: segment.filter.resume,
    resumeInclusionValue: segment.filter.resumeOperator ?? 'includes',
    location: segment.filter.candidateLocation,
    tags: mapIdsToOptions(segment.filter.tagIds, tags),
    page: 1,
  } as ApplicationListingFilters;
}
