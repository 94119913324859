import React, { useState } from 'react';
import { AlertObject, AlertType } from '../../../../../../../components/Alert';
import { ListCandidate } from '../../../../../../../entities/applicant_tracking/ListCandidate';
import { BulkActionButton } from '../BulkActionButton';
import { BulkRejectModal } from './BulkRejectModal';
import { BULK_ACTION_ERROR_MESSAGE } from '..';
import { checkIfSelectedCandidatesHaveSameStatus } from '../utils';

interface PropTypes {
  candidates: ListCandidate[];
  selectedCandidates: ListCandidate[];
  selectionCountLabel: string;
  reloadPage: () => void;
  setAlert: (alert: AlertObject) => void;
}

function actionStatusAndTooltipText(selectedCandidates: ListCandidate[]) {
  const alreadyRejected = selectedCandidates.every(
    (candidate) => candidate.application.status === 'rejected',
  );
  const actionDisabled =
    alreadyRejected ||
    selectedCandidates.length === 0 ||
    !checkIfSelectedCandidatesHaveSameStatus(selectedCandidates);

  const toolTipText = actionDisabled
    ? alreadyRejected
      ? 'All selected candidates have already been rejected'
      : 'Bulk rejection is only available for candidates that share the same status'
    : 'Reject Candidate';

  return { actionDisabled, toolTipText };
}

export function RejectCandidateButton(props: PropTypes) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { actionDisabled, toolTipText } = actionStatusAndTooltipText(
    props.selectedCandidates,
  );

  const handleModalClose = (succeeded: boolean) => {
    if (succeeded) {
      props.reloadPage();
      props.setAlert({
        message: (
          <span>
            <b>{props.selectionCountLabel}</b> were successfully rejected!
          </span>
        ),
        type: AlertType.Success,
      });
    } else if (succeeded === false) {
      props.setAlert({
        message: BULK_ACTION_ERROR_MESSAGE,
        type: AlertType.Danger,
      });
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <BulkActionButton
        iconName='bi-file-x'
        toolTipText={toolTipText}
        disabled={actionDisabled}
        onClick={() => setIsModalOpen(true)}
        analyticsId='bulk-reject-candidate-button'
      />
      {isModalOpen && (
        <BulkRejectModal
          applicationIds={props.selectedCandidates.map(
            (candidate) => candidate.application.id,
          )}
          selectionCountLabel={props.selectionCountLabel}
          onClose={handleModalClose}
        />
      )}
    </>
  );
}
