import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { CheckboxButton } from '../../CheckboxButton';
import {
  GroupedOption,
  groupOptionsByCategory,
} from '../../GroupedMultiSelect';
import { SelectOption } from '../../Select';

interface PropTypes {
  placementClass?: string;
  options: GroupedOption[];
  onChange: (key: string, selected: boolean) => void;
}

function Option(props: {
  option: GroupedOption;
  onChange: (key: string, selected: boolean) => void;
}) {
  const [checked, setChecked] = useState(props.option.selected);
  useEffect(() => setChecked(props.option.selected), [props.option.selected]);

  return (
    <CheckboxButton
      key={props.option.value}
      name={props.option.label}
      label={props.option.label}
      checked={checked}
      disabled={props.option.isDisabled}
      labelClasses='py-2'
      labelSpanClasses='text-dark-500'
      onChange={() => {
        props.onChange(props.option.value, !checked);
        setChecked(!checked);
      }}
    />
  );
}

function sortDisabledOptionsToTop(options: SelectOption[] | GroupedOption[]) {
  const result = [];

  const categories = options
    .filter((option) => option.isTitle)
    .map((option) => option.value);

  categories.forEach((category) => {
    const categoryItems = options.filter(
      (option) => (option as GroupedOption)?.category === category,
    );

    const disabledItems = categoryItems.filter((option) => option.isDisabled);
    const enabledItems = categoryItems.filter((option) => !option.isDisabled);

    result.push({
      label: category,
      value: category,
      isTitle: true,
    });

    result.push(...disabledItems);
    result.push(...enabledItems);
  });

  return result;
}

export const DropdownMenu = (props: PropTypes) => {
  const options = groupOptionsByCategory(props.options, 'asc');
  const sortedOptions = sortDisabledOptionsToTop(options);

  return (
    <ul
      className={classNames(
        'dropdown-menu px-2h',
        styles['dropdown-menu'],
        props.placementClass || '',
      )}
      aria-labelledby='user-dropdown-toggle'
    >
      {sortedOptions.map((option) => {
        return option.isTitle ? (
          <div className='fs-5 fw-bold py-2 text-primary' key={option.value}>
            {option.label}
          </div>
        ) : (
          <Option
            key={option.value}
            option={option as GroupedOption}
            onChange={props.onChange}
          />
        );
      })}
    </ul>
  );
};
