import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { SortingIcon } from '../../../../../../../components/SortingIcon';
import { TableHeader as TableHeaderLabel } from '../../../../../../../components/TableHeader';
import { CheckboxButton } from '../../../../../../../components/CheckboxButton';
import { SortingColumn } from '../../../../../../../utils/sorting';

interface PropTypes {
  sortColumn: SortingColumn;
  checked: boolean;
  checkboxDisabled?: boolean;
  onSelectedCandidatesUpdate: (checked: boolean) => void;
  onSortUpdate: (sortingColumn: SortingColumn) => void;
}

interface ColumnPropTypes {
  key: string;
  label: string;
  type: string;
  tooltipText?: string;
  isSortable?: boolean;
  className?: string;
}

const columns: ColumnPropTypes[] = [
  {
    key: 'candidate',
    label: 'Candidate',
    type: 'string',
    isSortable: true,
    className: 'col-3',
  },
  {
    key: 'jobs',
    label: 'Job Application',
    tooltipText: 'Candidate job application.',
    type: 'string',
    isSortable: false,
    className: 'col-2',
  },
  {
    key: 'updated_at',
    label: 'Updated At',
    tooltipText: 'Latest candidate application update.',
    type: 'string',
    className: 'col-2',
  },
  {
    key: 'channel',
    label: 'Channel',
    type: 'string',
    isSortable: false,
    className: 'col-2',
  },
  {
    key: 'stages',
    label: 'Current Stage',
    type: 'string',
    isSortable: false,
    className: 'col-2',
  },
  {
    key: 'actions',
    label: 'Actions',
    tooltipText: 'Your pending actions.',
    type: 'string',
    isSortable: false,
    className: 'col-1',
  },
  {
    key: 'dropdown_menu',
    label: '',
    type: 'string',
    isSortable: false,
    className: 'col-1',
  },
];

function TableHeaderColumnName(
  props: PropTypes & {
    tag: string;
    label: string;
    idx: number;
    type: string;
    tooltipText: string;
    isSortable: boolean;
  },
) {
  const oppositeDirection =
    props.sortColumn.direction === 'asc' ? 'desc' : 'asc';

  return props.isSortable ? (
    <a
      className={classNames('d-flex justify-content-start align-items-center')}
      href={'#'}
      onClick={() =>
        props.onSortUpdate({
          columnName: props.tag,
          direction:
            props.tag === props.sortColumn.columnName
              ? oppositeDirection
              : 'asc',
        })
      }
    >
      <TableHeaderLabel label={props.label} tooltipText={props.tooltipText} />
      <SortingIcon
        sortDirection={props.sortColumn.direction}
        isSelected={props.tag === props.sortColumn.columnName}
        className={'ms-auto'}
      />
    </a>
  ) : (
    <TableHeaderLabel label={props.label} tooltipText={props.tooltipText} />
  );
}

export function TableHeader(props: PropTypes) {
  return (
    <thead className={classNames('text-nowrap')}>
      <tr>
        <th
          className={classNames(
            styles['table-head-border-bottom'],
            'pb-3 left-col right-col',
          )}
        >
          <CheckboxButton
            name={null}
            label={null}
            checked={props.checked}
            onChange={() => props.onSelectedCandidatesUpdate(!props.checked)}
            disabled={props.checkboxDisabled}
          />
        </th>
        {columns.map(
          ({ key, label, tooltipText, type, isSortable, className }, idx) => {
            return (
              <th
                className={classNames(
                  idx === columns.length - 1 && styles['actions-column'],
                  styles['table-head-border-bottom'],
                  'pb-3',
                  className,
                )}
                key={idx}
              >
                <TableHeaderColumnName
                  {...props}
                  tag={key}
                  label={label}
                  idx={idx}
                  tooltipText={tooltipText}
                  type={type}
                  isSortable={isSortable ?? true}
                />
              </th>
            );
          },
        )}
      </tr>
    </thead>
  );
}
