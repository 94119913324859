import { Application } from '../../entities/Application';
import { appendQueryParam } from '../url';

export function applicationAverageRating(
  application: Application,
  precision: number = 1,
): string | null {
  const attributeRating = application.attributeRating;
  const applicationRating = application.applicationRating;

  if (!attributeRating && !applicationRating) {
    return null;
  }

  if (!attributeRating) {
    return applicationRating.toFixed(precision);
  }

  if (!applicationRating) {
    return attributeRating.toFixed(precision);
  }

  return ((applicationRating + attributeRating) / 2).toFixed(precision);
}

export function addApplicationUrl(candidateId: number, referral?: boolean) {
  const url = appendQueryParam(
    '/applicant_tracking/applications/new',
    'candidate_id',
    candidateId.toString(),
  );

  if (referral) {
    return appendQueryParam(url, 'referral', 'true');
  } else {
    return url;
  }
}
