import React from 'react';
import { GenericEntry } from '..';
import { Application } from '../../../../entities/Application';
import { Simple } from '../../../../entities/ApplicationFeed';
import { User } from '../../../../entities/User';
import { AuthorName } from '../AuthorName';
import { AlertObject } from '../../../../components/Alert';

interface PropTypes {
  application: Application;
  currentUser: User;
  isLastItemInPage: boolean;
  entry: Simple;
  reloadApplication: () => void;
  reloadFeed: () => void;
  setAlert: (data: AlertObject) => void;
}

export function ReferralCreatedEntry(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-person-up'
      iconClassName='bg-blue-gray-light'
      title={
        <>
          <strong>{props.application.candidate.name}</strong> was referred by{' '}
          <AuthorName name={props.entry.authorName} /> for the{' '}
          <strong>{props.entry.jobName}</strong> job.
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}
