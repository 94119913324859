import { ApplicationListingFilters } from '.';
import {
  SearchInclusionOperator,
  SearchSegmentFilter,
} from '../../../../../../../entities/v1/applicant_tracking/SearchSegment';

export function mapFilterToSearchSegmentRequest(
  filter: ApplicationListingFilters,
): SearchSegmentFilter {
  return {
    applicationStatuses: filter.statuses.map((status) => status.value),
    candidateLocation: filter.location,
    channels: filter.channels?.map((channel) => channel.value),
    dateRange: filter.dateRange.range,
    email: filter.email,
    emailOperator: filter.emailInclusionValue as SearchInclusionOperator,
    endDate: filter.dateRange.end?.toDate(),
    jobIds: filter.jobs?.map((job) => parseInt(job.value, 10)),
    jobStageIds: filter.jobStages?.map((jobStage) =>
      parseInt(jobStage.value, 10),
    ),
    name: filter.name,
    nameOperator: filter.nameInclusionValue as SearchInclusionOperator,
    resume: filter.resume,
    resumeOperator: filter.resumeInclusionValue as SearchInclusionOperator,
    rightToWorkLocationIds: filter.rightToWorkLocations?.map((location) =>
      parseInt(location.value, 10),
    ),
    rightToWorkLocationOperator:
      filter.rightToWorkLocationValue as SearchInclusionOperator,
    sourceIds: filter.sources?.map((source) => parseInt(source.value, 10)),
    startDate: filter.dateRange.start?.toDate(),
    tagIds: filter.tags?.map((tag) => parseInt(tag.value, 10)),
  };
}
