import classNames from 'classnames';
import React from 'react';
import { SelectOption } from '..';
import { OptionProps, components } from 'react-select';
import { TruncateText } from '../../TruncateText';
import { SpanTooltip } from '../../SpanTooltip';

function OptionBody(props: OptionProps & { optionData: SelectOption }) {
  return (
    <div
      className={classNames(
        'd-flex flex-nowrap',
        { 'justify-content-between': !props.isMulti },
        props.optionData.classNames,
      )}
    >
      {props.isMulti && (
        <input
          type='checkbox'
          checked={props.isSelected}
          onChange={(e) => {
            e.stopPropagation();
          }}
          disabled={props.isDisabled}
          className='form-check-input me-2'
        />
      )}
      <TruncateText
        text={props.optionData.label}
        ellipsize={true}
        tooltip={true}
        className={classNames(props.isDisabled && 'text-gray-700')}
      />
      {props.optionData.subLabel}
    </div>
  );
}

export function Option(props: OptionProps) {
  const optionData: SelectOption = props.data as SelectOption;

  return (
    <components.Option {...props}>
      {optionData.tooltipText ? (
        <SpanTooltip
          text={<OptionBody {...props} optionData={optionData} />}
          tooltipText={optionData.tooltipText}
          placement='top-end'
        />
      ) : (
        <OptionBody {...props} optionData={optionData} />
      )}
    </components.Option>
  );
}
