import React, { useEffect, useState } from 'react';
import { SearchSegment } from '../../../../../../../../entities/v1/applicant_tracking/SearchSegment';
import {
  GroupedOption,
  GroupedSelect,
} from '../../../../../../../../components/GroupedReactSelect';
import { SelectOption } from '../../../../../../../../components/Select';
import styles from './styles.module.scss';
import { appendQueryParamToCurrentTab } from '../../../../../../../../utils/url';

interface PropTypes {
  segments: SearchSegment[];
  selectedSegment: SearchSegment;
  setSelectedSegment: (segment: SearchSegment) => void;
}

export const SEGMENT_PARAM = 'segment_id';

function isSelectedSegment(
  segment: SearchSegment,
  selectedSegment: SearchSegment,
) {
  if (selectedSegment === null) return false;

  return segment.id === selectedSegment.id;
}

function mapSegmentToOption(
  segment: SearchSegment,
  selectedSegment: SearchSegment,
): SelectOption {
  return {
    value: segment.id.toString(),
    label: segment.name,
    selected: isSelectedSegment(segment, selectedSegment),
  };
}

function buildGroupedOptions(
  segments: SearchSegment[],
  selectedSegment?: SearchSegment,
): GroupedOption[] {
  return [
    {
      label: 'Public',
      options: segments
        .filter((segment) => !segment.isPrivate)
        .map((segment) => mapSegmentToOption(segment, selectedSegment)),
    },
    {
      label: 'My Own',
      options: segments
        .filter((segment) => segment.isPrivate)
        .map((segment) => mapSegmentToOption(segment, selectedSegment)),
    },
  ];
}

function selectedSegment(
  options?: GroupedOption[],
  selectedSegment?: SearchSegment,
) {
  if (!selectedSegment || !options) {
    return null;
  }

  return options
    .flatMap((group) => group.options)
    .find((option) => option.selected);
}

export function SegmentSelect(props: PropTypes) {
  const [options, setOptions] = useState<GroupedOption[]>(null);

  useEffect(() => {
    if (props.segments) {
      setOptions(buildGroupedOptions(props.segments, props.selectedSegment));
    }
  }, [props.segments, props.selectedSegment]);

  const handleChange = (option: SelectOption) => {
    const selected = props.segments.find(
      (i) => i.id.toString() === option.value,
    );

    props.setSelectedSegment(selected);
    appendQueryParamToCurrentTab(SEGMENT_PARAM, selected.id.toString());
  };

  return (
    <div className={styles['select-container']}>
      <GroupedSelect
        mandatory
        isSearchable
        placeholder='Select Saved Filters'
        isClearable={false}
        options={options || []}
        isLoading={options == null}
        selected={selectedSegment(options, props.selectedSegment)}
        onChange={handleChange}
      />
    </div>
  );
}
