import _ from 'lodash';
import { DateFilter } from '../../../../../../../components/DateRangePicker';
import { SortingColumn } from '../../../../../../../utils/sorting';
import { SelectOption } from '../../../../../../../components/Select';
import { getFiltersFromUrl } from './filterUrlParams';

export interface ApplicationListingFilters {
  statuses: SelectOption[];
  jobStages: SelectOption[];
  jobs: SelectOption[];
  sources: SelectOption[];
  channels: SelectOption[];
  rightToWorkLocations: SelectOption[];
  rightToWorkLocationValue: string;
  dateRange: DateFilter;
  page: number;
  sortingColumn: SortingColumn;
  name: string;
  nameInclusionValue: string;
  email: string;
  emailInclusionValue: string;
  resume: string;
  resumeInclusionValue: string;
  location: string;
  tags: SelectOption[];
}

type FILTER_CATEGORY = 'Candidate' | 'Job Application';

export interface FilterType {
  name: string;
  value: string;
  category: FILTER_CATEGORY;
  default?: boolean;
}

export const BOOLEAN_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const INCLUSION_OPTIONS = [
  { label: 'Includes', value: 'includes' },
  { label: 'Exact match', value: 'exact_match' },
  { label: 'Does not include', value: 'excludes' },
];

const DEFAULT_INCLUSION_VALUE = INCLUSION_OPTIONS[0].value;

export const AVAILABLE_FILTERS_TYPES: FilterType[] = [
  {
    name: 'Name',
    value: 'name',
    category: 'Candidate',
  },
  {
    name: 'Email',
    value: 'email',
    category: 'Candidate',
  },
  {
    name: 'Location',
    value: 'location',
    category: 'Candidate',
  },
  {
    name: 'Job',
    value: 'jobs',
    category: 'Job Application',
    default: true,
  },
  {
    name: 'Job Stage',
    value: 'jobStages',
    category: 'Job Application',
    default: true,
  },
  {
    name: 'Application Statuses',
    value: 'statuses',
    category: 'Job Application',
    default: true,
  },
  {
    name: 'Applied At',
    value: 'dateRange',
    category: 'Job Application',
    default: true,
  },
  {
    name: 'Channel',
    value: 'channels',
    category: 'Job Application',
  },
  {
    name: 'Source',
    value: 'sources',
    category: 'Job Application',
  },
  {
    name: 'Right to work in location',
    value: 'rightToWorkLocations',
    category: 'Job Application',
  },
  {
    name: 'Resume',
    value: 'resume',
    category: 'Job Application',
  },
];

export function getAvailableFilters(
  atsTagsPanelEnabled: boolean,
): FilterType[] {
  const filters = [...AVAILABLE_FILTERS_TYPES];

  if (atsTagsPanelEnabled) {
    filters.push({
      name: 'Tags',
      value: 'tags',
      category: 'Job Application',
    });
  }

  return filters;
}

export const DEFAULT_FILTER_TYPES = AVAILABLE_FILTERS_TYPES.filter(
  (filter) => filter.default,
).map((filter) => filter.value);

export const DEFAULT_FILTER_STATE: ApplicationListingFilters = {
  statuses: [],
  jobStages: [],
  jobs: [],
  sources: [],
  channels: [],
  rightToWorkLocations: [],
  rightToWorkLocationValue: null,
  dateRange: {
    start: null,
    end: null,
    range: null,
  },
  sortingColumn: {
    columnName: 'updated_at',
    direction: 'desc',
  },
  page: 1,
  name: null,
  nameInclusionValue: DEFAULT_INCLUSION_VALUE,
  email: null,
  emailInclusionValue: DEFAULT_INCLUSION_VALUE,
  resume: null,
  resumeInclusionValue: DEFAULT_INCLUSION_VALUE,
  location: null,
  tags: [],
};

export function resetPageIfFiltersChanged(
  previousFilterStates: ApplicationListingFilters,
  currentFilterStates: ApplicationListingFilters,
  setFilterStates: (filters: ApplicationListingFilters) => void,
): void {
  const previousFiltersWithoutPage = _.omit(previousFilterStates, 'page');
  const currentFiltersWithoutPage = _.omit(currentFilterStates, 'page');

  if (
    !_.isEqualWith(
      previousFiltersWithoutPage,
      currentFiltersWithoutPage,
      lodashCustomCompare,
    )
  ) {
    setFilterStates({ ...currentFilterStates, page: 1 });
  }
}

function lodashCustomCompare(value1: any, value2: any): boolean {
  if (!value1 && !value2) {
    return true;
  }

  // Let lodash handle the rest of the comparisons
  return undefined;
}

export function countFilledFilters(filters: ApplicationListingFilters): number {
  let count = 0;

  const multiSelectFilters = [
    filters.statuses,
    filters.jobStages,
    filters.jobs,
    filters.sources,
    filters.channels,
    filters.rightToWorkLocations,
    filters.tags,
  ];
  const singleValueFilters = [
    filters.name,
    filters.email,
    filters.resume,
    filters.location,
  ];

  if (filters.dateRange.start && filters.dateRange.end) count++;

  multiSelectFilters.forEach((filter) => {
    if (filter?.length > 0) count++;
  });

  singleValueFilters.forEach((filter) => {
    if (filter) count++;
  });

  return count;
}

export function resetIfFilterNotSelected(
  filterTypes: FilterType[],
  atsTagsPanelEnabled: boolean,
  setFilters: (
    filters: (
      prevFilters: ApplicationListingFilters,
    ) => ApplicationListingFilters,
  ) => void,
): void {
  // This logic shall be reverted when we remove the atsTagsPanelEnabled feature flag
  const availableFilters = getAvailableFilters(atsTagsPanelEnabled);

  availableFilters.forEach((filterType) => {
    // Don't reset default filters or filters that have URL values
    if (DEFAULT_FILTER_TYPES.includes(filterType.value)) return;

    const filter = filterTypes.find((type) => type.value === filterType.value);
    const urlFilters = getFiltersFromUrl();

    // Don't reset if filter exists in URL
    if (!filter && !urlFilters[filterType.value]) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterType.value]: DEFAULT_FILTER_STATE[filterType.value],
      }));
    }
  });
}
