import React from 'react';
import { CloseableModal } from '../../../../../components/CloseableModal';
import { Button } from 'reactstrap';
import { addApplicationUrl } from '../../../../../utils/applicant_tracking/application';

interface PropTypes {
  candidateId: number;
  referralFlow?: boolean;
  onClose: () => void;
}

export function NewJobApplicationModal(props: PropTypes) {
  return (
    <CloseableModal
      keyboard
      backdrop
      className='modal-dialog-centered'
      onClose={props.onClose}
      isOpen={props.candidateId !== null}
      size='md'
      headerTitle='New Job Application'
      bodyChildren={
        <div className='fw-semibold'>
          <div className='fs-4 mb-6h'>
            This candidate already exists. Would you like to add a new job
            application?
          </div>
          <div className='d-flex justify-content-end'>
            <Button className='me-2' color='danger' onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              color='primary'
              onClick={() =>
                (window.location.href = addApplicationUrl(
                  props.candidateId,
                  props.referralFlow,
                ))
              }
            >
              Continue
            </Button>
          </div>
        </div>
      }
    />
  );
}
