import {
  UseFormClearErrors,
  UseFormSetError,
  UseFormTrigger,
} from 'react-hook-form';
import {
  Trigger,
  SendEmailTrigger,
  AutoRejectTrigger,
  SendSelfSchedulingEmailTrigger,
} from '../../../../../../entities/applicant_tracking/Trigger';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';

const resetError = (
  clearErrors: UseFormClearErrors<CreateJobDto>,
  trigger: UseFormTrigger<CreateJobDto>,
) => {
  clearErrors(`triggers`);
  trigger();
};

const setErrors = (setError: UseFormSetError<CreateJobDto>) => {
  setError(`triggers`, {
    type: 'required',
    message: 'Please add required fields for all triggers',
  });
};

const validateSendEmailTrigger = (trigger: SendEmailTrigger) => {
  const applicationStatusesPresent = trigger.applicationStatuses.length > 0;
  const emailTemplatePresent = !!trigger.emailTemplateId;
  const stageIdsPresent = trigger.stageIds.length > 0;

  return applicationStatusesPresent && emailTemplatePresent && stageIdsPresent;
};

const validateSendSelfSchedulingEmailTrigger = (
  trigger: SendSelfSchedulingEmailTrigger,
) => {
  const interviewTemplatePresent = !!trigger.interviewTemplateId;
  const stageIdsPresent = trigger.stageIds.length > 0;

  return interviewTemplatePresent && stageIdsPresent;
};

const validateAutoRejectTrigger = (trigger: AutoRejectTrigger) => {
  const mainTriggerValid =
    trigger.rejectAnswer !== undefined &&
    (trigger.questionId || trigger.applicationField);

  const sendEmailTriggerValid =
    !trigger.sendRejectionEmail || !!trigger.emailTemplateId;

  return mainTriggerValid && sendEmailTriggerValid;
};

export function validateTriggers(
  triggers: Trigger[],
  setError: UseFormSetError<CreateJobDto>,
  clearErrors: UseFormClearErrors<CreateJobDto>,
  trigger: UseFormTrigger<CreateJobDto>,
) {
  let errors = 0;

  triggers.forEach((trigger) => {
    switch (trigger.type) {
      case 'auto_reject':
        if (!validateAutoRejectTrigger(trigger as AutoRejectTrigger)) {
          errors += 1;
        }
        break;
      case 'send_email':
        if (!validateSendEmailTrigger(trigger as SendEmailTrigger)) {
          errors += 1;
        }
        break;
      case 'send_self_scheduling_email':
        if (
          !validateSendSelfSchedulingEmailTrigger(
            trigger as SendSelfSchedulingEmailTrigger,
          )
        ) {
          errors += 1;
        }
        break;
      default:
        break;
    }
  });

  errors > 0 ? setErrors(setError) : resetError(clearErrors, trigger);
}
