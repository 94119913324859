import React, { useEffect } from 'react';
import { LabelledSelect } from '../../../../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../../../../components/Select';
import { TriggerRowDropdown } from './TriggerRowDropdown';
import { Trigger } from '../../../../../../../../entities/applicant_tracking/Trigger';
import { mapDelayDaysToSelectOptions } from '../../triggerSelectOptions';

interface PropTypes {
  trigger: Trigger;
  delayOptions: SelectOption[];
  disabled?: boolean;
  hideLabel?: boolean;
  useWideDropdown?: boolean;
  suffixLabel?: boolean;
  setDelayOptions: (options: SelectOption[]) => void;
}

export const delayOptionValues = (trigger: Trigger, suffixLabel?: boolean) =>
  mapDelayDaysToSelectOptions(
    trigger.delayDays?.toString() || '0',
    suffixLabel,
  );

export function DelayDropdown(props: PropTypes) {
  const initDelayOptions = delayOptionValues(props.trigger, props.suffixLabel);

  useEffect(() => {
    props.setDelayOptions(initDelayOptions);
  }, []);

  return (
    <TriggerRowDropdown className={!props.useWideDropdown && 'col-1'}>
      <LabelledSelect
        options={props.delayOptions}
        selected={props.delayOptions.find((v: SelectOption) => v.selected)}
        labelClassName='fs-5 text-nowrap'
        label={!props.hideLabel && 'Delay Days'}
        placeholder='Select days'
        disabled={props.disabled}
        onChange={(o) =>
          props.setDelayOptions(
            mapDelayDaysToSelectOptions(o.value, props.suffixLabel),
          )
        }
      />
    </TriggerRowDropdown>
  );
}
