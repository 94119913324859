import { GroupedOption } from '.';

export function mapDisabledOptionKeys(options: GroupedOption[]) {
  return options.map((group) => ({
    ...group,
    options: group.options.map((option) => ({
      ...option,
      isDisabled: option.isDisabled || false,
    })),
  }));
}
