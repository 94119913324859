import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { ClearIcon } from '../ClearIcon';
import { DropdownIcon } from '../DropdownIcon';
import { SelectOption } from '../Select';
import { SelectItem } from '../SelectItem';
import styles from './styles.module.scss';
import { Size } from '../../utils/types/Size';

export interface LabelledMultiSelectPropTypes {
  /** Options available to be selected. */
  options: SelectOption[];
  /** Selected options. */
  selected: SelectOption[];
  /** Label. */
  label: string;
  /** Text shown when no options are selected. */
  placeholder: string;
  /** Show in the input when there's a single selected value. */
  singleSelectedText: string;
  /**
   * Shows in the input when there are multiple selected values.
   *
   * A '%d' placeholder can be used and will be replaced by the count.
   */
  manySelectedText: string;
  /** Called when the input changes, with the newly selected options. */
  onChange: (newOptions: SelectOption[]) => void;
  /** Classes to apply to the wrapping element. */
  className?: string;
  /** Max number of elements that can be selected. */
  max?: number;
  /** Min number of elements that must be selected. (Just for label, validation not yet added) */
  min?: number;
  /** Called when the dropdown is closed **/
  onClose?: () => void;
  /** Adds select all text to options list in multiselect dropdown. It allows to check/uncheck all options at once. */
  hasSelectAll?: boolean;
  /** Replaces select all label */
  selectAllLabel?: string;
  /** Adds clear all option to multiselect dropdown **/
  disableClearIcon?: boolean;
  /** Adds search option to multiselect dropdown **/
  disableSearch?: boolean;
  disabled?: boolean;
  labelClassName?: string;
  size?: Size;
  /** Allows usage of custom search logic (Particularly to avoid searching for category labels in grouped select) **/
  filterOptions?: (
    options: SelectOption[],
    filter: string,
  ) => Promise<SelectOption[]> | SelectOption[];
}

const ArrowRenderer = (props: any) => {
  return (
    <DropdownIcon
      menuIsOpen={props.expanded}
      isDisabled={false}
      classNames='me-1 fs-5'
    />
  );
};

/**
 * Labelled multi-select component.
 * @deprecated
 */
export function LabelledMultiSelect(props: LabelledMultiSelectPropTypes) {
  function handleChange(selected: SelectOption[]) {
    if (props.max && selected.length > props.max) {
      return;
    }

    props.onChange(selected);
  }

  const [isOpen, setIsOpen] = useState(false);
  const [isClear, setIsClear] = useState(false);
  let timeoutId = null;

  function handleClose() {
    setIsOpen(false);
    props.onClose?.();
  }

  function handleBlur() {
    timeoutId = setTimeout(() => {
      if (isOpen) {
        handleClose();
      }
    });
  }

  function handleFocus() {
    clearTimeout(timeoutId);
  }

  function handleClear() {
    setIsClear(true);
  }

  function handleClick(event) {
    if (
      event.currentTarget.className === 'dropdown-container' &&
      Boolean(event.currentTarget.getAttribute('aria-expanded')) &&
      isOpen
    ) {
      handleClose();
    } else if (!isOpen) {
      setIsOpen(true);
    }
  }

  useEffect(() => {
    if (isClear) {
      handleClose();
      setIsClear(false);
    }
  }, [isClear]);

  return (
    <div
      className={classNames(props.className)}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onClick={handleClick}
    >
      <div
        className={classNames(
          props.labelClassName ||
            'px-1 mb-2 d-flex flex-nowrap align-items-end',
        )}
      >
        {props.label && (
          <>
            <label className='me-auto text-truncate'>
              {props.label}
              {props.min && props.min > 0 && (
                <span className='ps-1 text-danger'>*</span>
              )}
            </label>
            {props.max && (
              <small className='text-muted text-nowrap'>Max. {props.max}</small>
            )}
          </>
        )}
      </div>
      <MultiSelect
        className={classNames(
          styles.rmsc,
          styles[`rmsc-${props.size || 'md'}`],
          'w-100',
          'fs-5',
        )}
        options={props.options}
        value={props.selected}
        onChange={handleChange}
        labelledBy={props.placeholder}
        hasSelectAll={props.hasSelectAll}
        disableSearch={props.disableSearch}
        disabled={props.disabled}
        ClearSelectedIcon={
          props.disableClearIcon ? (
            <React.Fragment />
          ) : (
            <ClearIcon classNames='me-2 fs-5' onClick={handleClear} />
          )
        }
        ArrowRenderer={ArrowRenderer}
        ItemRenderer={SelectItem}
        valueRenderer={() => (
          <ValueRender
            count={props.selected.filter((value) => !value.isDisabled).length}
            none={props.placeholder}
            one={props.singleSelectedText}
            many={props.manySelectedText}
          />
        )}
        overrideStrings={
          props.selectAllLabel && { selectAll: props.selectAllLabel }
        }
        filterOptions={props.filterOptions}
      />
    </div>
  );
}

function ValueRender(props: {
  count: number;
  none: string;
  one: string;
  many: string;
}) {
  if (props.count === 0) {
    return <>{props.none}</>;
  } else if (props.count === 1) {
    return <>{props.one}</>;
  } else {
    return <>{props.many.replace('%d', props.count.toString())}</>;
  }
}
