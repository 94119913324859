import React from 'react';
import { AddFilterButton } from './AddFilterButton';
import { SegmentSelect } from './SegmentSelect';
import { ToggleFiltersButton } from './ToggleFiltersButton';
import { ApplicationListingFilters, FilterType } from '../utils';
import { SearchSegment } from '../../../../../../../entities/v1/applicant_tracking/SearchSegment';
import { getQueryParam } from '../../../../../../../utils/url';
import { SEARCH_PARAM } from '../../../OpenSearch';

interface PropTypes {
  atsTagsPanelEnabled: boolean;
  isFiltersOpen: boolean;
  filters: ApplicationListingFilters;
  filterTypes: FilterType[];
  segments: SearchSegment[];
  selectedSegment: SearchSegment;
  setIsFiltersOpen: (value: boolean) => void;
  setFilterTypes: (filterTypes: FilterType[]) => void;
  setSelectedSegment: (segment: SearchSegment) => void;
}

export function FiltersHeader(props: PropTypes) {
  const search = getQueryParam(SEARCH_PARAM);

  return (
    <div className='d-flex justify-content-between'>
      <AddFilterButton
        atsTagsPanelEnabled={props.atsTagsPanelEnabled}
        filterTypes={props.filterTypes}
        setFilterTypes={props.setFilterTypes}
        setIsFiltersOpen={props.setIsFiltersOpen}
      />
      <div className='d-flex gap-3 align-items-center'>
        {search && (
          <span className='fs-5'>
            Showing results for <b>&quot;{search}&quot;</b>
          </span>
        )}
        <ToggleFiltersButton
          filters={props.filters}
          isFiltersOpen={props.isFiltersOpen}
          setIsFiltersOpen={props.setIsFiltersOpen}
        />
        <SegmentSelect
          segments={props.segments}
          selectedSegment={props.selectedSegment}
          setSelectedSegment={props.setSelectedSegment}
        />
      </div>
    </div>
  );
}
