import React from 'react';
import classNames from 'classnames';
import { SpanTooltip } from '../../../../../../../../components/SpanTooltip';
import { Button } from 'reactstrap';
import { Icon } from '../../../../../../../../components/Icon';
import styles from './styles.module.scss';
import { InterviewerParticipant } from '../../../../../../../../entities/applicant_tracking/InterviewerParticipant';

interface PropTypes {
  participant: InterviewerParticipant;
  candidateView: boolean;
  allowIgnoreParticipantCalendar: boolean;
  onIgnoringCalendar: (participant: InterviewerParticipant) => void;
}

export function IgnoreParticipantCalendarButton(props: PropTypes) {
  if (props.candidateView) return;
  if (!props.allowIgnoreParticipantCalendar) return;

  return (
    <SpanTooltip
      text={
        <Button
          color={'muted'}
          className={classNames(
            styles['ignore-calendar'],
            'border-0 w-10 pe-0 py-0',
          )}
          disabled={false}
          onClick={() => props.onIgnoringCalendar(props.participant)}
        >
          <Icon
            name={`bi-calendar-${
              props.participant.ignoreCalendar ? 'plus' : 'minus'
            }`}
            topStyle={'2px'}
          />
        </Button>
      }
      tooltipText={
        props.participant.ignoreCalendar
          ? 'Consider this participant calendar and availability'
          : 'Ignore this participant calendar and availability'
      }
      trigger='hover'
    />
  );
}
