import { ApplicationListingFilters } from './index';
import moment from 'moment';
import { SortingDirection } from '../../../../../../../utils/types/SortingDirection';
import { DateRange } from '../../../../../../../components/DateRangePicker';
import { humanize } from '../../../../../../../utils/humanize';

export function updateUrlWithFilters(filters: ApplicationListingFilters) {
  const params = new URLSearchParams(window.location.search);

  // Text filters with inclusion types
  if (filters.name) {
    params.set('f_name', filters.name);
    params.set('f_name_type', filters.nameInclusionValue);
  }

  if (filters.email) {
    params.set('f_email', filters.email);
    params.set('f_email_type', filters.emailInclusionValue);
  }

  if (filters.resume) {
    params.set('f_resume', filters.resume);
    params.set('f_resume_type', filters.resumeInclusionValue);
  }

  // Simple text filters
  if (filters.location) {
    params.set('f_location', filters.location);
  }

  // Array filters
  if (filters.jobs?.length) {
    params.set('f_jobs', filters.jobs.map((j) => j.value).join(','));
  }

  if (filters.jobStages?.length) {
    params.set('f_job_stages', filters.jobStages.map((j) => j.value).join(','));
  }

  if (filters.statuses?.length) {
    params.set('f_status', filters.statuses.map((s) => s.value).join(','));
  } else {
    params.delete('f_status');
  }

  if (filters.channels?.length) {
    params.set('f_channels', filters.channels.map((c) => c.value).join(','));
  }

  if (filters.sources?.length) {
    params.set('f_sources', filters.sources.map((s) => s.value).join(','));
  }

  if (filters.tags?.length) {
    params.set('f_tags', filters.tags.map((t) => t.value).join(','));
  }

  // Right to work location with boolean
  if (filters.rightToWorkLocations?.length) {
    params.set(
      'f_rtw_locations',
      filters.rightToWorkLocations.map((l) => l.value).join(','),
    );
    if (filters.rightToWorkLocationValue !== null) {
      params.set('f_rtw_value', filters.rightToWorkLocationValue);
    }
  }

  // Date range
  if (filters.dateRange) {
    const { start, end, range } = filters.dateRange;

    if (start) {
      params.set('f_date_start', start.toISOString());
    }

    if (end) {
      params.set('f_date_end', end.toISOString());
    }

    if (range) {
      params.set('f_date_range', range);
    }
  }

  // Sorting
  if (filters.sortingColumn) {
    params.set('f_sort', filters.sortingColumn.columnName);
    params.set('f_sort_d', filters.sortingColumn.direction);
  }

  // Page
  if (filters.page !== 1) {
    params.set('f_page', filters.page.toString());
  }

  // Update URL without reload
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${params.toString()}`,
  );
}

export function getFiltersFromUrl(): Partial<ApplicationListingFilters> {
  const params = new URLSearchParams(window.location.search);
  const filters: Partial<ApplicationListingFilters> = {};

  // Text filters with inclusion types
  if (params.has('f_name')) {
    filters.name = params.get('f_name');
    filters.nameInclusionValue = params.get('f_name_type');
  }

  if (params.has('f_email')) {
    filters.email = params.get('f_email');
    filters.emailInclusionValue = params.get('f_email_type');
  }

  if (params.has('f_resume')) {
    filters.resume = params.get('f_resume');
    filters.resumeInclusionValue = params.get('f_resume_type');
  }

  // Simple text filters
  if (params.has('f_location')) {
    filters.location = params.get('f_location');
  }

  // Array filters
  if (params.has('f_jobs')) {
    const values = params.get('f_jobs').split(',');
    filters.jobs = values.map((value) => ({ value, label: value }));
  }

  if (params.has('f_job_stages')) {
    const values = params.get('f_job_stages').split(',');
    filters.jobStages = values.map((value) => ({ value, label: value }));
  }

  if (params.has('f_status')) {
    const values = params.get('f_status').split(',');
    filters.statuses = values.map((value) => ({
      value,
      label: humanize(value),
    }));
  }

  if (params.has('f_channels')) {
    const values = params.get('f_channels').split(',');
    filters.channels = values.map((value) => ({ value, label: value }));
  }

  if (params.has('f_sources')) {
    const values = params.get('f_sources').split(',');
    filters.sources = values.map((value) => ({ value, label: value }));
  }

  if (params.has('f_tags')) {
    const values = params.get('f_tags').split(',');
    filters.tags = values.map((value) => ({ value, label: value }));
  }

  // Right to work location
  if (params.has('f_rtw_locations')) {
    const values = params.get('f_rtw_locations').split(',');
    filters.rightToWorkLocations = values.map((value) => ({
      value,
      label: value,
    }));

    if (params.has('f_rtw_value')) {
      filters.rightToWorkLocationValue = params.get('f_rtw_value');
    }
  }

  // Date range
  if (
    params.has('f_date_start') ||
    params.has('f_date_end') ||
    params.has('f_date_range')
  ) {
    filters.dateRange = {
      start: params.has('f_date_start')
        ? moment(params.get('f_date_start'))
        : null,
      end: params.has('f_date_end') ? moment(params.get('f_date_end')) : null,
      range: params.get('f_date_range') as DateRange,
    };
  }

  // Sorting
  if (params.has('f_sort')) {
    const direction = params.get('f_sort_d') as SortingDirection;
    filters.sortingColumn = {
      columnName: params.get('f_sort'),
      direction: direction || 'neutral',
    };
  }

  // Page
  const page = params.get('f_page');
  if (page) {
    filters.page = parseInt(page, 10);
  }

  return filters;
}
