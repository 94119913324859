import React, { useEffect } from 'react';
import { SelectOption } from '../../../../../../../../components/Select';
import { TriggerRowDropdown } from './TriggerRowDropdown';
import {
  SendEmailTrigger,
  SendSelfSchedulingEmailTrigger,
} from '../../../../../../../../entities/applicant_tracking/Trigger';
import { mapEmailTemplatesToSelectOptions } from '../../triggerSelectOptions';
import { EmailTemplate } from '../../../../../../../../entities/EmailTemplate';
import { LabelledSelect } from '../../../../../../../../components/LabelledSelect';

interface PropTypes {
  trigger: SendEmailTrigger | SendSelfSchedulingEmailTrigger;
  emailTemplates: EmailTemplate[];
  emailTemplateOptions: SelectOption[];
  setEmailTemplateOptions: (options: SelectOption[]) => void;
  isMandatory: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
}

export function EmailTemplateDropdown(props: PropTypes) {
  const initEmailTemplateOptions = mapEmailTemplatesToSelectOptions(
    props.emailTemplates,
    props.trigger.emailTemplateId?.toString(),
  );

  useEffect(() => {
    props.setEmailTemplateOptions(initEmailTemplateOptions);
  }, []);

  return (
    <TriggerRowDropdown>
      <LabelledSelect
        options={props.emailTemplateOptions}
        selected={props.emailTemplateOptions.find(
          (v: SelectOption) => v.selected,
        )}
        mandatory={props.isMandatory}
        labelClassName='fs-5 text-nowrap'
        label={!props.hideLabel && 'Email Template'}
        placeholder='Select template'
        disabled={props.disabled}
        onChange={(options) =>
          props.setEmailTemplateOptions(
            mapEmailTemplatesToSelectOptions(
              props.emailTemplates,
              options.value,
            ),
          )
        }
      />
    </TriggerRowDropdown>
  );
}
