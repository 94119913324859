import React, { useState } from 'react';
import { BULK_ACTION_ERROR_MESSAGE } from '..';
import { AlertObject, AlertType } from '../../../../../../../components/Alert';
import { ListCandidate } from '../../../../../../../entities/applicant_tracking/ListCandidate';
import { BulkActionButton } from '../BulkActionButton';
import { User } from '../../../../../../../entities/User';
import {
  checkIfAnySelectedCandidatesAreRedacted,
  checkIfSelectedCandidatesHaveSameStatus,
} from '../utils';
import { SendEmailModal } from '../../../../../../SendEmailModal';
import { ApplicationService } from '../../../../../../../services/applicant_tracking/ApplicationService';
import { SendEmailDto } from '../../../../../../../services/dtos/EmailDto';
import { ApiError } from '../../../../../../../services/ApiService/errors/ApiError';

interface PropTypes {
  candidates: ListCandidate[];
  currentUser: User;
  selectedCandidates: ListCandidate[];
  selectionCountLabel: string;
  reloadPage: () => void;
  setAlert: (alert: AlertObject) => void;
}

export function SendEmailButton(props: PropTypes) {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
  const actionDisabled =
    props.selectedCandidates.length === 0 ||
    !checkIfSelectedCandidatesHaveSameStatus(props.selectedCandidates) ||
    checkIfAnySelectedCandidatesAreRedacted(props.selectedCandidates);
  const toolTipText = actionDisabled
    ? 'Bulk e-mail sending is only available for candidates that share the same status and are not-redacted'
    : 'Send Email';

  const applicationIds = props.selectedCandidates.map((c) => c.application.id);

  const onSendEmail = async (sendEmailProps: SendEmailDto) => {
    await ApplicationService.sendEmails(applicationIds, sendEmailProps)
      .then(() => {
        props.reloadPage();
        props.setAlert({
          message: (
            <span>
              The email was successfully sent to{' '}
              <span className='fw-bold'>{props.selectionCountLabel}</span>
            </span>
          ),
          type: AlertType.Success,
        });
      })
      .catch((e: ApiError) => {
        props.setAlert({
          message: e.body?.message || BULK_ACTION_ERROR_MESSAGE,
          type: AlertType.Danger,
        });
      })
      .finally(() => setIsEmailModalOpen(false));
  };

  return (
    <>
      <BulkActionButton
        iconName='bi-envelope'
        toolTipText={toolTipText}
        disabled={actionDisabled}
        onClick={() => setIsEmailModalOpen(true)}
        analyticsId='bulk-send-email-button'
      />
      {isEmailModalOpen && (
        <SendEmailModal
          applicationIds={applicationIds}
          toEmail={
            props.selectedCandidates.length == 1 &&
            props.selectedCandidates[0].email
          }
          hasEmailConnected={props.currentUser.hasEmailConnected}
          isOpen={isEmailModalOpen}
          onCloseModal={() => setIsEmailModalOpen(false)}
          onSendEmail={onSendEmail}
        />
      )}
    </>
  );
}
